.navBar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  z-index: 99;
  padding-top: 20px;
  width: 100%;
  -webkit-box-shadow: 0px 11px 23px 11px rgba(0, 0, 0, 0.97);
  box-shadow: 0px 11px 23px 11px rgba(0, 0, 0, 0.97);
  @apply px-1 md:px-10;
  > div {
    width: 100%;
    margin: auto;
  }

  //margin: 120px;
}
#hexagon {
  width: 100px;
  height: 57.735px;
  background: #020af5;
  position: absolute;
  right: 25px;
  z-index: 1;
  bottom: -31px;
  // opacity: 0.8;
  -webkit-box-shadow: 0px 0px 50px 38px #020af5;
  box-shadow: 0px 0px 50px 38px #020af5;
  filter: blur(15px);
  transform: scale(1.6);
  rotate: 0deg;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
#hexagon::before {
  content: "";
  position: absolute;
  top: -28.8675px;
  left: 0;
  width: 0;

  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 28.8675px solid #020af5;
}
#hexagon::after {
  content: "";
  position: absolute;
  bottom: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 28.8675px solid #020af5;
}
.buton {
  background: rgb(234, 3, 134);
  background: linear-gradient(
    90deg,
    rgba(234, 3, 134, 1) 0%,
    rgba(220, 25, 97, 1) 100%
  );
  transition: 0.3s;
  &:hover {
    background: rgb(17, 51, 249);
    background: linear-gradient(
      90deg,
      rgba(17, 51, 249, 1) 0%,
      rgba(106, 128, 248, 1) 100%
    );
  }
}
