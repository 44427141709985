.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.9);
}

.modal {
  position: fixed;
  top: 43%;
  margin-top: 30px;
  width: 95%;
  z-index: 101;
  border-radius: 20px;

  color: white;

  left: 50%;
  transform: translate(-50%, -50%);
}
.beforeBar {
  position: relative;
}
.beforeBar::before {
  content: url("../../assets//mainPage/blueBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -4px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}
.closeBtn {
  position: absolute;
  right: 40px;
  top: 50%;
  padding: 5px 10px;
  font-size: 25px;
  border-radius: 10px;
  color: #fff;
  border: solid 5px #020af5;
  transform: translateY(-50%);
  @media only screen and (max-width: 1100px) {
    font-size: 20px;
    border: solid 4px #020af5;
    right: 00px;
  }
}
