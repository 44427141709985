.beforeBar {
  position: relative;
}
.beforeBar::before {
  content: url("../../assets//mainPage/pinkOblicRectangle.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -42px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}
.pinkBar {
  @media only screen and (min-width: 1024px) {
    position: absolute;
    top: 6px;
    left: -44px;
  }
  @media only screen and (min-width: 1280px) {
    top: 9px;
    left: -44px;
  }
  @media only screen and (min-width: 1700px) {
    top: 12px;
    left: -54px;
    scale: 1.3;
  }
}
.cirlce {
  position: absolute;
  filter: blur(50px);
  width: 250px;
  overflow: hidden;
  margin-top: -20px;
  @media only screen and (min-width: 1024px) {
    width: 150px;
    filter: blur(50px);
    margin-top: 0px;
  }
  @media only screen and (min-width: 1280px) {
    width: 250px;
    filter: blur(50px);
  }
}
