.beforeBar {
  position: relative;
}
.beforeBar::before {
  content: url("../../assets//mainPage/blueBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -4px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}
@media only screen and (min-width: 768px) {
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 39px;
    grid-row-gap: 42px;
    img {
      display: none;
    }
    span {
      position: relative;
    }
    span::before {
      content: url("../../assets//mainPage/pinkRectangleBox.svg");
      position: absolute;

      top: -4px;
      transform: scale(1);
      left: -69px;
      object-fit: cover;
      @media only screen and (min-width: 768px) {
        top: -1px;
        transform: scale(0.6);
        left: -46px;
      }
      @media only screen and (min-width: 1024px) {
        top: 0px;
        transform: scale(0.6);
        left: -54px;
      }
      @media only screen and (min-width: 1280px) {
        top: -1px;
        transform: scale(0.8);
        left: -58px;
      }
      @media only screen and (min-width: 1700px) {
        top: -2px;
        transform: scale(1);
        left: -61px;
      }
    }
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div2 {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div3 {
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div4 {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div5 {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div6 {
    grid-area: 3 / 2 / 4 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// @media only screen and (max-width: 768px) {
//   .secondBox .parent img {
//     display: none;
//   }
// }

.secondBox .beforeBar::before {
  content: url("../../assets//mainPage/pinkOblicRectangle.svg");
}
@media only screen and (min-width: 768px) {
  .secondBox .parent span::before {
    content: url("../../assets//mainPage/blueRectangleBox.svg");
  }
}

@media only screen and (min-width: 768px) {
  .secondBox .parent img {
    display: none;
  }
}
