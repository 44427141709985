.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.95);
  @media (max-width: 568px) {
    background: #000;
  }
}

.modal {
  position: fixed;
  top: 0vh;
  margin-top: 30px;
  width: 95%;
  z-index: 101;
  border-radius: 20px;
  min-height: 90%;
  color: white;

  display: flex;
  gap: 20px;
  overflow-y: scroll; /* Enable vertical scrolling */
  max-height: 84vh;
  left: 50%;
  transform: translate(-50%, 0);
}
.before {
  position: relative;
}
.before::before {
  content: url("../../assets//mainPage/pinkX.svg");
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  transform: scale(0.5);
  left: 2px;
  object-fit: cover;
  @media only screen and (min-width: 768px) {
    top: 11px;
    transform: scale(0.5);
    left: 30px;
  }
  @media only screen and (min-width: 1024px) {
    top: 19px;
    transform: scale(0.5);
    left: 63px;
  }
  @media only screen and (min-width: 1280px) {
    top: 13px;
    transform: scale(0.7);
    left: 58px;
  }
  @media only screen and (min-width: 1700px) {
    top: 20px;
    transform: scale(1);
    left: 79px;
  }
}
.closeBtn {
  position: absolute;
  right: 40px;
  top: 50%;
  padding: 5px 10px;
  font-size: 25px;
  border-radius: 10px;
  color: #fff;
  border: solid 5px #e30c76;
  transform: translateY(-50%);
  @media only screen and (max-width: 1100px) {
    font-size: 20px;
    border: solid 2px #e30c76;
    right: 20px;
  }
}
