.buton {
  // background: rgb(234, 3, 134);
  background: linear-gradient(
    90deg,
    rgba(234, 3, 134, 1) 0%,
    rgba(220, 25, 97, 1) 100%
  );
  color: red;
  transition: 0.3s;
  &:hover {
    background: rgb(17, 51, 249);
    background: linear-gradient(
      90deg,
      rgba(17, 51, 249, 1) 0%,
      rgba(106, 128, 248, 1) 100%
    );
  }
}
