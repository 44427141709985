.before {
  position: relative;
}
.before::before {
  content: url("../../assets//mainPage//letterX.png");
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  transform: scale(0.2);
  left: -10px;
  object-fit: cover;
  @media only screen and (min-width: 768px) {
    top: -10px;
    transform: scale(0.27);
    left: -64px;
  }
  @media only screen and (min-width: 1024px) {
    top: -3px;
    transform: scale(0.27);
    left: -70px;
  }
  @media only screen and (min-width: 1280px) {
    top: -10px;
    transform: scale(0.4);
    left: -70px;
  }
  @media only screen and (min-width: 1700px) {
    top: -5px;
    transform: scale(0.5);
    left: -90px;
  }
}
#hexagon {
  width: 100px;
  height: 57.735px;
  background: #020af5;
  position: absolute;
  right: 2px;
  z-index: 1;
  top: 180px;
  // opacity: 0.8;
  -webkit-box-shadow: 0px 0px 50px 38px #020af5;
  box-shadow: 0px 0px 50px 38px #020af5;
  filter: blur(15px);
  transform: scale(1.6);
  rotate: -45deg;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
#hexagon::before {
  content: "";
  position: absolute;
  top: -28.8675px;
  left: 0;
  width: 0;

  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 28.8675px solid #020af5;
}
#hexagon::after {
  content: "";
  position: absolute;
  bottom: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 28.8675px solid #020af5;
}
.buton {
  background: rgb(234, 3, 134);
  background: linear-gradient(
    90deg,
    rgba(234, 3, 134, 1) 0%,
    rgba(220, 25, 97, 1) 100%
  );
  transition: 0.3s;
  &:hover {
    background: rgb(17, 51, 249);
    background: linear-gradient(
      90deg,
      rgba(17, 51, 249, 1) 0%,
      rgba(106, 128, 248, 1) 100%
    );
  }
}
