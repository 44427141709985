.wordCarousel {
  font-size: 36px;
  font-weight: 100;
  color: #eee;
  display: flex;
  @media (max-width: 768px) {
    margin-top: 50px;
  }

  div {
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 140px;
    padding-top: 10px;

    li {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      //padding: 0 50px;
      height: 92px;

      margin-bottom: 62px;
      display: block;
      font-weight: bold;
      img {
        margin: 0 auto;
      }
      @media (max-width: 768px) {
        max-width: 160px;
      }
    }
  }
}

.flip6 {
  animation: flip6 18s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@keyframes flip6 {
   0% {
    margin-top: 0px;
    animation-delay: 0s; /* No delay for the first keyframe */
  }
  8.33% {
    margin-top: 0px;
    animation-delay: 0s; /* No delay for the second keyframe */
  }
  25% {
    margin-top: -154px;
    animation-delay: 0s; /* No delay for the third keyframe */
  }
  33.33% {
    margin-top: -154px;
    animation-delay: 0s; /* No delay for the fourth keyframe */
  }
  50% {
    margin-top: -308px;
    animation-delay: 0s; /* No delay for the fifth keyframe */
  }
  58.33% {
    margin-top: -308px;
    animation-delay: 0s; /* No delay for the sixth keyframe */
  }
  75% {
    margin-top: -462px;
    animation-delay: 0s; /* No delay for the seventh keyframe */
  }
  83.33% {
    margin-top: -462px;
    animation-delay: 0s; /* No delay for the eighth keyframe */
  }
  100% {
    margin-top: -616px;
    animation-delay: 5s; /* Delay for the ninth keyframe */
  }
}
