.beforeBar {
  position: relative;
}

.beforeBar::before {
  content: url("../../assets//mainPage/blueBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -4px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}
.secondComp .beforeBar::before {
  content: url("../../assets//mainPage/oblicPinkBar.svg");
}
.firstUL li {
  position: relative;
  margin-left: 33px;
  padding: 2px 0;
  font-weight: 300;
  @media only screen and (min-width: 1700px) {
    padding: 10px 0;
  }
}
.firstUL li::before {
  content: url("../../assets//mainPage//squarePink.svg");
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  transform: scale(1);
  left: -30px;
  object-fit: cover;
  @media only screen and (min-width: 1700px) {
    top: 10px;
  }
}
.secondComp .firstUL li::before {
  content: url("../../assets/mainPage//sqaureBlue.svg");
}
.leftBox {
  min-width: 33px;
  height: 45px;
  margin-top: -1px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: start;
  box-sizing: border-box;
  @media only screen and (min-width: 768px) {
    min-width: 30px;
    height: auto;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 25px;
  }
  @media only screen and (min-width: 1024px) {
    min-width: 90px;
    height: auto;
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 15px;
  }
  @media only screen and (min-width: 1200px) {
    min-width: 140px;
    height: auto;
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 5px;
  }
  @media only screen and (min-width: 1700px) {
    min-width: 190px;
    height: auto;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 5px;
  }
  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    box-sizing: border-box;
    transform: scale(0.7);
    filter: blur(3px);
    @media only screen and (min-width: 768px) {
      min-width: 50px;
      height: auto;
      transform: scale(1);
    }
    @media only screen and (min-width: 1024px) {
      min-width: 70px;
      height: auto;
    }
    @media only screen and (min-width: 1200px) {
      min-width: 100px;
      height: auto;
    }
    @media only screen and (min-width: 1700px) {
      min-width: 130px;
      height: auto;
    }
  }
}

.rectangle {
  position: absolute;
  height: 101%;
  width: 35px;
  background-color: white;
  opacity: 0.2;
  top: 5px;
  left: 15px;
  border-radius: 5px;

  filter: blur(1px);
  @media only screen and (min-width: 380px) {
    height: 101%;
  }
  @media only screen and (min-width: 768px) {
    width: 66px;
    left: 22px;
    border-radius: 10px;
    height: 104%;
  }
  @media only screen and (min-width: 1024px) {
    width: 96px;
    left: 28px;
  }
  @media only screen and (min-width: 1200px) {
    width: 142px;
    filter: blur(5px);
    left: 20px;
    top: 15px;
  }
  @media only screen and (min-width: 1700px) {
    width: 172px;
    filter: blur(1px);
    left: 29px;
    top: 15px;
  }
}
