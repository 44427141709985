.beforeBar {
  position: relative;
}
.beforeBar::before {
  content: url("../../assets//mainPage/oblicPinkBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -4px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}

.blueBar::before {
  content: url("../../assets//mainPage/blueBar.svg");
}
