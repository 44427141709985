.leftProcent {
  background: rgb(235, 1, 137);
  background: linear-gradient(
    90deg,
    rgba(235, 1, 137, 1) 0%,
    rgba(230, 9, 124, 1) 49%,
    rgba(196, 4, 72, 1) 100%
  );
  font-size: 40px;
  font-weight: bold;
  @media only screen and (max-width: 410px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
  }

  //   @media only screen and (min-width: 768px) {
  //     font-size: 45px;
  //     text-align: left;
  //   }
  @media only screen and (min-width: 1300px) {
    font-size: 50px;
  }
  @media only screen and (min-width: 1700px) {
    font-size: 55px;
  }
  p:nth-child(2) {
    font-size: 12px;
    @media only screen and (min-width: 1700px) {
      font-size: 18px;
    }
  }
}
.rightProcent {
  font-size: 40px;
  font-weight: bold;
  @media only screen and (max-width: 410px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
  }

  //   @media only screen and (min-width: 768px) {
  //     font-size: 45px;
  //     text-align: left;
  //   }
  @media only screen and (min-width: 1300px) {
    font-size: 50px;
  }
  @media only screen and (min-width: 1700px) {
    font-size: 55px;
  }

  p:nth-child(2) {
    font-size: 12px;
    @media only screen and (min-width: 1700px) {
      font-size: 18px;
    }
  }
  background: rgb(111, 133, 248);
  background: linear-gradient(
    90deg,
    rgba(111, 133, 248, 1) 0%,
    rgba(3, 31, 201, 1) 100%
  );
}
.firstUL li,
.secondUL li {
  position: relative;
  margin-left: 33px;
  padding: 4px 0;
  font-weight: 300;
}
.firstUL li::before {
  content: url("../../assets//mainPage//squarePink.svg");
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  transform: scale(1);
  left: -30px;
  object-fit: cover;
}

.secondUL li::before {
  content: url("../../assets/mainPage//sqaureBlue.svg");
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  transform: scale(1);
  left: -30px;
  object-fit: cover;
}
.before {
  position: relative;
  padding-left: 58px;
  @apply md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header;
}
.before::before {
  content: url("../../assets//mainPage/blueX.svg");
  width: 30px;
  height: 30px;
  position: absolute;
  transform: scale(0.4);
  object-fit: cover;

  left: 8px;
  top: 5px;
  @media only screen and (min-width: 380px) {
    left: 8px;
    top: 8px;
    transform: scale(0.5);
  }

  @media only screen and (min-width: 768px) {
    top: 11px;
    transform: scale(0.5);
    left: 9px;
  }
  @media only screen and (min-width: 1024px) {
    top: 19px;
    transform: scale(0.5);
    left: 21px;
  }
  @media only screen and (min-width: 1280px) {
    top: 15px;
    transform: scale(0.7);
    left: 18px;
  }
  @media only screen and (min-width: 1700px) {
    top: 25px;
    transform: scale(1);
    left: 49px;
  }
}
.beforeBar {
  position: relative;

  padding-left: 48px;
  @apply md:pl-0;
}
.beforeBar::before {
  content: url("../../assets//mainPage/oblicPinkBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -1px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}

#hexagon {
  width: 650px;
  //height: 980px;
  position: absolute;
  filter: blur(50px);
  left: -180px;
  bottom: -370px;
  z-index: 0;
  @media only screen and (max-width: 1024px) {
    left: -180px;
    width: 350px;
    bottom: -220px;
  }
}
