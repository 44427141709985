.before {
  position: relative;
  padding-left: 55px;
  @apply md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header;
}
.before::before {
  content: url("../../assets//mainPage/blueX.svg");
  width: 30px;
  height: 30px;
  position: absolute;

  transform: scale(0.4);

  object-fit: cover;
  left: 8px;
  top: 5px;
  @media only screen and (min-width: 380px) {
    left: 8px;
    top: 8px;
    transform: scale(0.5);
  }
  @media only screen and (min-width: 768px) {
    top: 11px;
    transform: scale(0.5);
    left: 9px;
  }
  @media only screen and (min-width: 1024px) {
    top: 19px;
    transform: scale(0.5);
    left: 21px;
  }
  @media only screen and (min-width: 1280px) {
    top: 15px;
    transform: scale(0.7);
    left: 18px;
  }
  @media only screen and (min-width: 1700px) {
    top: 25px;
    transform: scale(1);
    left: 49px;
  }
}
.beforeBar {
  position: relative;
}
.beforeBar::before {
  content: url("../../assets//mainPage/oblicPinkBar.svg");
  position: absolute;

  top: -4px;
  transform: scale(0.8);
  left: -4px;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    top: -4px;
    transform: scale(0.8);
    left: -42px;
  }
  @media only screen and (min-width: 1024px) {
    top: -2px;
    transform: scale(0.8);
    left: -50px;
  }
  @media only screen and (min-width: 1280px) {
    top: 2px;
    transform: scale(0.8);
    left: -52px;
  }
  @media only screen and (min-width: 1700px) {
    top: 0px;
    transform: scale(1);
    left: -61px;
  }
}
