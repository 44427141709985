body {
  margin: 0;
  font-family: "StolziBook", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Stolzi";
  src: local("Stolzi"),
    url("./assets/fonts//Stolzl-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "StolziBook";
  src: local("Stolzi"), url("./assets/fonts//Stolzl-Book.woff2") format("woff2");
}
@font-face {
  font-family: "StolziBold";
  src: local("Stolzi"), url("./assets/fonts//Stolzl-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "StolziMed";
  src: local("StolziMed"),
    url("./assets/fonts/Stolzl-Medium.woff2") format("woff2");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;

@tailwind components;
@tailwind utilities;

.slick-track {
  display: flex;
  align-items: center;
  min-height: 100%;
}
.slick-slide {
  width: 100% !important;
}
.slick-track:nth-child(4) {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  min-height: 100%;
}
