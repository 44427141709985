.before {
  position: relative;
}
.advisors .before::before {
  content: url("../../assets//mainPage/blueX.svg");
}
.before::before {
  content: url("../../assets//mainPage/pinkX.svg");
  width: 30px;
  height: 30px;
  position: absolute;

  transform: scale(0.4);

  object-fit: cover;
  left: 8px;
  top: 4px;
  @media only screen and (min-width: 380px) {
    left: 8px;
    top: 8px;
    transform: scale(0.5);
  }
  @media only screen and (min-width: 768px) {
    top: 11px;
    transform: scale(0.5);
    left: 30px;
  }
  @media only screen and (min-width: 1024px) {
    top: 19px;
    transform: scale(0.5);
    left: 63px;
  }
  @media only screen and (min-width: 1280px) {
    top: 13px;
    transform: scale(0.7);
    left: 58px;
  }
  @media only screen and (min-width: 1700px) {
    top: 25px;
    transform: scale(1);
    left: 79px;
  }
}
